<template>
  <v-container fluid @keydown.enter.prevent="pesquisar">
    <v-row>
      <v-col >
          <div class="title-container">
              <h1 class="h1">Faturamento</h1>
              <v-tooltip bottom v-if="contaspendentes > 0">
                  <template v-slot:activator="{ on }">
                      <span v-on="on" class="badge"> {{ contaspendentes }} </span>
                  </template>
                  <span>Existe(m) {{ contaspendentes }} conta(s) pendente(s) de Faturamento</span>
              </v-tooltip>
          </div>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12">
        <v-data-table :loading="isLoading('get')" :items="filteredData" :search="search" :headers="headersWithDynamicInfo" loading-text="Carregando Informações...."
         no-data-text="Selecione os campos desejados para a pesquisa e clique em 'PESQUISAR'" item-key="id" class="fixed-header-table" fixed-header  height="600px" :items-per-page="-1" >
          <!-- Pesquisa, Relatórios e Filtros -->
          <template v-slot:top>
            <!-- Relatórios -->
                  <div class="align-self-top py-1 text-end" left bottom> 
                     <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn class="elevation-0 ml-5" @click="exportToExcel" v-bind="attrs" v-on="on" style="background-color: transparent;">
                          <v-icon color="green" size="28">mdi-file-excel</v-icon>
                        </v-btn>
                      </template>
                      <span>Exportar para Excel</span>
                    </v-tooltip>
                    <v-menu eager offset-x left offset-y bottom v-model="menu" content-class="menu-content">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon class=" align-center mx-8 " color="green" size="30" v-bind="attrs" v-on="on">
                          mdi-printer
                        </v-icon>
                      </template>
                      <v-container class="container">
                        <template v-if="selectedBudgets.length <= 0 && multipleSelections.length <= 0">
                          <RelatorioOrcamentoFiltroOrcamento :displayedData="displayedData"  :dataInicioOrcamento="filters.dateStart.value" :username="lista.apelido"
                          :dataFimOrcamento="filters.dateEnd.value"></RelatorioOrcamentoFiltroOrcamento>
                          <RelatorioOrcamentoFiltroRealizado :displayedData="displayedData" :dataInicioOrcamento="filters.dateStart.value" :username="lista.apelido"
                          :dataFimOrcamento="filters.dateEnd.value"></RelatorioOrcamentoFiltroRealizado>
                        </template>
                        <template v-if="selectedBudgets.length > 0">
                          <EvolucoesPorEspecialidade ref="evolucoesPorEspecialidadeComponent" :data_inicio="selectedOrcamentoDtInicio" :data_fim="selectedOrcamentoDtFim" :evolutions="idsEvolucoes" :filtered-data="filteredDatas" v-show="false" :username="lista.apelido"> </EvolucoesPorEspecialidade>
                          <orcamento ref="orcamento" :key="'orcamento-' + selectedBudgets.join(',')" :selectedBudgets="selectedBudgets" :faturamento="faturamento" :username="lista.apelido"> </orcamento>
                          <OrcamentoSemAditivo :selectedBudgets="selectedBudgets" :username="lista.apelido"></OrcamentoSemAditivo>
                          <!-- <OrcamentoProrrogacao :selectedBudgets="selectedBudgets" :username="lista.apelido"></OrcamentoProrrogacao> -->
                          <Aditivo :selectedBudgets="selectedBudgets" :username="lista.apelido"></Aditivo>
                          <Conta ref="conta" v-if="isContaFechada" :key="'conta' + selectedBudgets.join(',')" :selectedBudgets="selectedBudgets" :username="lista.apelido"></Conta>
                          <RecursoGlosa v-if="isContaRecursoGlosa" :selectedBudgets="selectedBudgets" :username="lista.apelido"></RecursoGlosa>
                          <div  @click="gerarXMLIsaolado" >
                            <Xml ref="xml" v-if="isContaFechada" :key="'xml' + selectedBudgets.join(',')" :selectedBudgets="selectedBudgets" :username="lista.apelido"></Xml>
                          </div>
                          <div @click="gerarXMLIsaoladoRecrusoGlosa" >
                            <XmlGLosa v-if="isContaRecursoGlosa" :selectedBudgets="selectedBudgets" :username="lista.apelido"></XmlGLosa>
                          </div>
                          <GuiaSadt ref="guiaSadt2" :selectedBudgets="selectedBudgets" :username="lista.apelido"></GuiaSadt>
                          <GuiaSadtOutrasDespesas ref="guiaSadt2" :selectedBudgets="selectedBudgets" :username="lista.apelido"></GuiaSadtOutrasDespesas>
                          <div v-if="isLoadingEvolucoes" class="com-evolucoes">Carregando mais Relatórios...</div>
                          <div v-else-if="filteredDatas.length === 0" class="sem-evolucoes">Sem Evoluções Lançadas</div>
                          <div v-else>
                            <Evolucao :key="selectedOrcamentoId" :data_inicio="selectedOrcamentoDtInicio" :data_fim="selectedOrcamentoDtFim" :evolutions="idsEvolucoes" :filtered-data="filteredDatasEvolucao" :username="lista.apelido"></Evolucao>
                          </div>
                        </template>

                        <div v-if="filteredDatasTQT.length > 0" >
                        <TQT ref="evolucao" :data_inicio="selectedOrcamentoDtInicio"
                          :data_fim="selectedOrcamentoDtFim" :evolutions="idsEvolucoes" :filteredData="filteredDatasTQT" :username="lista.apelido">
                        </TQT>
                      </div>
                      <div v-if="filteredDatasGTT.length > 0" >
                        <GTT ref="evolucao" :key="selectedOrcamentoId" :data_inicio="selectedOrcamentoDtInicio"
                          :data_fim="selectedOrcamentoDtFim" :evolutions="filteredDatasGTT" :filteredData="filteredDatasGTT" :username="lista.apelido">
                        </GTT>
                      </div>
                        <template v-if="multipleSelections.length > 0">
                          <div  @click="gerarXMLAgrupado">
                            <XMLAgrupago ref="xmlagrupado" v-if="isContaFechadaXML" :key="'xml' + multipleSelections.join(',')" :selectedBudgets="multipleSelections"></XMLAgrupago>
                          </div>
                          <RelatorioXMLAgrupado  ref="relatorioxml" v-if="isContaFechadaXML" :displayedData="filteredData" :dataInicioOrcamento="filters.dateStart.value"  :username="lista.apelido"
                            :dataFimOrcamento="filters.dateEnd.value" :selectedIds="multipleSelections"></RelatorioXMLAgrupado>
                          <v-btn color="success" depressed size="small" 
                            :key="'lista-' + selectedBudgets.join(',')" @click="gerarXMLAgrupadoERelatorio">
                            <v-icon left>mdi-printer</v-icon> Gerar XML Agrupado + relação de Contas
                          </v-btn>
                        </template>
                        <v-btn color="success" depressed size="small" v-if="!isLoadingEvolucoes && isContaFechada && selectedBudgets.length > 0 && filteredDatas.length > 0"
                          :key="'lista-' + selectedBudgets.join(',')" @click="gerarRelatoriosCombinados">
                          <v-icon left>mdi-printer</v-icon> Gerar documentos para cobrança
                        </v-btn>
                         <div v-if="casa.length > 0" > 
                        <AvaliacaoCasa :selectedBudgets="selectedBudgets" :username="lista.apelido" ></AvaliacaoCasa>
                        </div>
                        <div v-if="captacao.length > 0" >
                          <Captacao :selectedBudgets="selectedBudgets" :username="lista.apelido" > </Captacao>
                        </div>
                        <div v-if="abemid.length > 0" >
                          <Abemid :selectedBudgets="selectedBudgets" :username="lista.apelido" > </Abemid>
                        </div>
                        <div v-if="nead.length > 0" >
                          <Nead :selectedBudgets="selectedBudgets" :username="lista.apelido" > </Nead>
                        </div>
                        <div v-if="pps.length > 0" >
                          <Pps :selectedBudgets="selectedBudgets" :username="lista.apelido" > </Pps>
                        </div>
                      </v-container>
                    </v-menu>
                  </div>
            <!-- Itens do campo de Pesquisa -->
            <div>
              <v-card dense class="mb-6"> 
                <h4 class="ml-4 mt-4" color></h4>
                <v-row dense class="py-2 px-4">
                  <v-col cols="2">
                    <v-text-field hide-details type="date" label="Data Início Orçamento" v-model="filters.dateStart.value"
                      outlined dense clearable/>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field hide-details type="date" label="Data Fim Orçamento" v-model="filters.dateEnd.value"
                      outlined dense clearable/>
                  </v-col>
                  <v-col :cols="2">
                    <v-select hide-details label="Operadoras" :items="operadoraOptions" v-model="filters.operadora.value"
                      item-text="nome" item-value="id" outlined dense clearable/>
                  </v-col>
                  <v-col :cols="2">
                    <v-select hide-details label="Modalidade" :items="modalidadeOptions" v-model="filters.modalidade.value" 
                      item-text="nome" item-value="id" outlined dense clearable/>
                  </v-col>
                  <v-col :cols="2">
                    <v-select hide-details label="Orçamento em Aberto / Aprovado" :items="manutencaoOptions" v-model="filters.manutencao.value" 
                    item-text="nome" item-value="value" outlined dense clearable/>
                  </v-col>
                  <v-col :cols="2">
                    <v-select hide-details label="Conta Fechada / Faturada" :items="fechamentoOptions" v-model="filters.fechamento.value" 
                    item-text="nome" item-value="value" outlined dense clearable/>
                  </v-col>
                </v-row>
                <v-row dense class="py-2 px-4">
                  <v-col cols="1">
                    <v-select hide-details label="Cob. Parcial" :items="cobrancaParcial" v-model="filters.cobranca_parcial.value" 
                    item-text="nome" item-value="value"  outlined dense clearable />
                  </v-col>
                  <v-col cols="1">
                    <v-text-field hide-details label="Senha" v-model="filters.senha.value" outlined dense clearable type="Number"/>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field hide-details label="ID do Orçamento / Conta" v-model="filters.id.value"  type="Number" outlined dense clearable>
                    </v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field hide-details label="ID do Paciente" v-model="filters.pacienteId.value"  type="Number" outlined dense clearable />
                  </v-col>
                  <v-col cols="2">
                    <v-text-field hide-details label="Nome do Paciente" v-model="filters.nomepaciente.value" outlined dense clearable @input="formatPatientName"/>
                  </v-col>
                  <v-col class="text-end">
                    <v-btn color="red" text class="mr-1" elevation="0" @click="clearFilters"> limpar Pesquisa </v-btn>
                    <!-- <v-btn color="gree" class="mr-1" elevation="0" @click="atualizar"> <v-icon left>mdi-reload </v-icon>Atualizar</v-btn> -->
                    <v-btn color="red" text class="mr-1" elevation="0" @click="clearSelecao" :disabled="!selectedBudgets.length && !multipleSelections.length"> limpar Seleção </v-btn>
                    <v-btn color="success" elevation="0" @click="applyFilters"> Pesquisar </v-btn>
                  </v-col>
                  
                </v-row>   
              </v-card>
              <!-- Campo de Pesquisa -->
              <v-row>
                <!-- <v-col cols="6 ml-4">
                  <v-text-field dense outlined v-model="search" label="Pesquisar" placeholder="Pesquise por Nome, Modalidade, Senha, ID, Convênio ou Nr da Guia do Prestador / Operadora." 
                  class="mx-0" append-icon="mdi-magnify"  />
                </v-col>     -->
                <v-col class="text-end">
                </v-col>    
                </v-row>
            </div>
          </template>
          <template v-slot:[`item.equipe`]="{ item }">
            <v-chip class="ma-1" v-for="i in item.equipe" :key="i.id">
              {{ i.first_name }} - {{ i.paciente_id }}
            </v-chip>
          </template>
          <template v-slot:[`item.data_inicio`]="{ item }">
            <span v-if="item.data_inicio">
              {{ item.data_inicio | formatDatabr }}
            </span>
          </template>
          <template v-slot:[`item.data_fim`]="{ item }">
            <span v-if="item.data_fim">
              {{ item.data_fim | formatDatabr }}
            </span>
          </template>
          <template v-slot:[`item.fatorcamento`]="{ item }">
            {{ item.fatorcamento | formatReal }}
          </template>
          <template v-slot:[`item.fatrealizado`]="{ item }">
            {{ item.fatrealizado | formatReal }}
          </template>
          <!-- <template v-slot:[`item.status`]="{ item }">
            <div>
              <v-icon v-if="item.manutencao === '1'" size="30" color="green">mdi-check-bold</v-icon>
            </div>
          </template> -->
          <template v-slot:[`item.selectMultiple`]="{ item }">
            <div class="d-flex align-center">
              <v-checkbox v-model="multipleSelections" :value="item.id" :disabled="isCheckboxDisabled(item)" @change="handleMultipleSelectionsChange" > </v-checkbox>
              <template v-if="item.xml_gerado_agrupado">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" color="green" >mdi-check</v-icon>
                  </template>
                  <span>XML Agrupado já gerado</span>
                </v-tooltip>
              </template>
            </div>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <!-- Exibe ícone de manutenção -->
            <template v-if="item.manutencao === '1' && item.fechamento === '0' && !item.realizarrecursoglosa">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" size="30" color="red">mdi-currency-usd</v-icon>
                </template>
                <span>Conta em Manutenção!!!</span>
              </v-tooltip>
            </template>

            <!-- Exibe ícone de fechamento -->
            <template v-if="item.fechamento === '1' && !item.realizarrecursoglosa">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" size="30" color="green">mdi-currency-usd</v-icon>
                </template>
                <span>Conta Fechada / Faturada!!!</span>
              </v-tooltip>
            </template>

            <!-- Exibe ícone de fechamento -->
            <template v-if="item.realizarrecursoglosa ">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" size="30" color="gree">mdi-currency-usd</v-icon>
                </template>
                <span>Recurso de Glosa!!!</span>
              </v-tooltip>
            </template>

            <!-- Exibe ícone de cobrança parcial -->
            <template v-if="item.cobranca_parcial === true && item.manutencao === '0'">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" size="30" color="orange">mdi-currency-usd</v-icon>
                </template>
                <span>Conta Cobrada Parcialmente!!!</span>
              </v-tooltip>
            </template>

            <!-- Exibe ícone de orçamento em aberto -->
            <template v-if="item.ativo === false">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" size="30" color="red">mdi-eye-outline</v-icon>
                </template>
                <span>Orçamento em Aberto!!!</span>
              </v-tooltip>
            </template>

            <!-- Exibe ícone de atendimento suspenso -->
            <template v-if="item.suspender_atendimento">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" size="30" color="red">mdi-hospital-building</v-icon>
                </template>
                Atendimento Suspenso
              </v-tooltip>
            </template>

            <!-- Exibe ícone de atendimento em andamento -->
            <template v-if="item.ativo === true && item.manutencao === '0' && !item.suspender_atendimento && !item.realizarrecursoglosa">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" size="30" color="blue">mdi-eye-outline</v-icon>
                </template>
                <span>Em Atendimento!!!</span>
              </v-tooltip>
            </template>
          </template>

          <template v-slot:[`item.realizarrecursoglosa`]="{ item }">
            <div>
              <v-icon v-if="item.realizarrecursoglosa === true" size="30" color="green">mdi-check-bold</v-icon>
            </div>
          </template>
          <template v-slot:[`item.valor_glosado`]="{ item }">
            <span v-if="item.valor_glosado">
              {{ item.valor_glosado | formatReal }}
            </span>
          </template>
          <template v-slot:[`item.fechamento`]="{ item }">
            <div>
              <v-icon v-if="item.fechamento === '1'" size="30" color="green">mdi-check-bold</v-icon>
            </div>
          </template>
          <template v-slot:[`item.senha_data_inicio`]="{ item }">
            <span v-if="item.senha_data_inicio">
              {{ item.senha_data_inicio | formatDatabr }}
            </span>
          </template>
          <template v-slot:[`item.senha_data_validade`]="{ item }">
            <span v-if="item.senha_data_validade">
              {{ item.senha_data_validade | formatDatabr }}
            </span>
          </template>
          <template v-slot:[`item.ativo`]="{ item }">
            <v-icon v-if="item.ativo" color="green">mdi-check-circle-outline</v-icon>
            <v-icon v-else color="red">mdi-circle-outline</v-icon>
          </template>
          <template v-slot:[`item.copiar`]="{ item }">
            <v-checkbox v-model="lista_items" :value="item.id"></v-checkbox>
          </template>
          <template v-slot:[`item.select_budget`]="{ item }">
            <div class="d-flex align-center justify-center">
              <v-checkbox :input-value="selectedBudgets[0] === item.id" @change="handleCheckboxChange(item)" ></v-checkbox>
              <template v-if="item.xml_gerado">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" color="green" >mdi-check</v-icon>
                  </template>
                  <span>XML já gerado</span>
                </v-tooltip>
              </template>
            </div>
          </template>
          <template  v-slot:[`item.edit`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" @click="onSelectRow(item, 'update')">
                  <v-icon color="green">mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Clique para editar</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.id`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on" :style="{ color: 
                                                              item.senha !== null &&
                                                              item.nr_guia_operador !== null &&
                                                              item.nr_guia_prestador !== null &&
                                                              item.senha_data_inicio !== null &&
                                                              item.senha_data_validade !== null
                                                              ? 'green' : 'red' }">{{ item.id }}</span>
              </template>
              <span v-if="item.senha !== null && 
                          item.nr_guia_operador !== null &&
                          item.nr_guia_prestador !== null &&
                          item.senha_data_inicio !== null &&
                          item.senha_data_validade !== null">Guia Completa</span>
              <span v-else>Guia Incompleta</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <!-- Manutenção e Edição da Conta  -->
    <FullscreenDialog v-model="dialog.update">
      <v-card>
        <v-card-title class="sticky-title title-border">
          Manutenção da conta Nrº {{ selectedRow.id }} | Paciente: {{selectedRow.paciente}} 
          <v-spacer></v-spacer>
          <v-btn icon class="sticky-icon" @click="dialog.update = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <EditForm :onsubmit="updateSession" :budgetId="selectedRow.id" :IdOrcamento="selectedRow.id"
            :updateSession="getSessions" :is-loading="isLoading.bind(this)" :set-loading="setLoading.bind(this)">
          </EditForm>
        </v-card-text>
      </v-card>
    </FullscreenDialog>
<!-- Modal para inserir Protocolo na Operadora -->
    <v-row>
        <v-dialog v-model="dialog.agrupado" max-width="500px">
          <v-card >
            <v-card-title class="sticky-title title-border" >
              Informações do Envio do Faturamento
              <v-spacer></v-spacer>
              <v-btn icon @click="dialog.agrupado = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-form ref="faturamentoForm" v-model="formValid">
                <v-text-field
                  v-model="data_envio_faturamento"
                  label="Data de Envio do Faturamento"
                  type="date"
                  :rules="requiredField"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="protocolo_operadora"
                  label="Protocolo Operadora"
                  :rules="requiredField"
                  required
                ></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions class="justify-end">
              <ConfirmButton :loading="isLoading('put:sessaoInformacao')" :block="false" class="mt-2 mr-2 " outlined
                :onConfirm="confirmarFaturamento" color="success">
                Salvar
              </ConfirmButton>
            </v-card-actions>
          </v-card>
        </v-dialog>
    </v-row>
<!-- Modal para inserir Protocolo Recurso de Glosa na Operadora -->
<v-row>
  <v-dialog v-model="dialog.agrupadoRecurso" v-show="dialog.agrupadoRecurso" max-width="500px">
    <v-card>
      <v-card-title class="sticky-title title-border">
        Informações do Envio do Recurso de Glosa
        <v-spacer></v-spacer>
        <v-btn icon @click="fechamodal('agrupadoRecurso')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="faturamentoForm" v-model="formValidGlosa">
          <v-text-field
            v-model="data_envio_recurso_glosa"
            label="Data de Envio do Recurso de Glosa"
            type="date"
            :rules="requiredField"
            required
          ></v-text-field>
          <v-text-field
            v-model="protocolo_glosa_operadora"
            label="Protocolo Operadora"
            :rules="requiredField"
            required
          ></v-text-field>
          <vuetify-money 
            label="Valor Glosado e Recorrido" 
            v-model="valor_glosado" 
            required
            clearable 
            :options="options"
            @input="handleValorGlosadoInput"
          ></vuetify-money>
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-end">
        <ConfirmButton :loading="isLoading('put:sessaoInformacao')" :block="false" class="mt-2 mr-2" outlined
          :onConfirm="confirmarFaturamento" color="success">
          Salvar
        </ConfirmButton>
      </v-card-actions>
    </v-card>
  </v-dialog>
</v-row>

  </v-container>
</template>

<script>
import api from "@/http";
// import moment from "moment";
import UtilsFunc from "../../service/utilsFunc";
import EditForm from './componentes/EditForm.vue';
import FullscreenDialog from "../FullscreenDialog.vue";
import Orcamento from '../relatorios/orcamento/Orcamento.vue';
import Xml from '../relatorios/xml/NovoXMLEditavel.vue';
import XMLAgrupago from '../relatorios/xml/NovoXMLEditavel.vue';
import XmlGLosa from '../relatorios/xml/XmlGlosa.vue';
import Conta from '../relatorios/conta/Conta.vue';
import RecursoGlosa from '../relatorios/conta/ContaRecursoGlosa.vue';
import '../../assets/css/styles.css';
import { mapGetters } from "vuex";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';


const { withCRUDUtils } = UtilsFunc
export default {
  name: "Faturamento",
  components: {
    ConfirmButton: () => import('../ConfirmButton.vue'),
    EditForm,
    Orcamento,
    Xml,
    XMLAgrupago,
    XmlGLosa,
    Conta,
    RecursoGlosa,
    FullscreenDialog,
    Evolucao: () => import('../relatorios/evolucoes/Evolucoes.vue'),
    TQT: () => import('../relatorios/evolucoes/Troca_TQT.vue'),
    GTT: () => import('../relatorios/evolucoes/Troca_GTT.vue'),
    EvolucoesPorEspecialidade: () => import('../relatorios/evolucoes/EvolucoesPorEspecialidade.vue'),
    // OrcamentoProrrogacao: () => import('../relatorios/orcamento/OrcamentoProrrogacao.vue'),
    Aditivo: () => import('../relatorios/aditivo/Aditivo.vue'),
    OrcamentoSemAditivo: () => import('../relatorios/orcamento/OrcamentoSemAditivo.vue'),
    GuiaSadt: () => import('../relatorios/sadt/Sadt.vue'),
    GuiaSadtOutrasDespesas: () => import('../relatorios/sadt/SadtOutrasDespesas.vue'),
    RelatorioOrcamentoFiltroOrcamento: () => import('../relatorios/orcamento/RelatorioOrcamentoFiltroOrcamento.vue'),
    RelatorioOrcamentoFiltroRealizado: () => import('../relatorios/orcamento/RelatorioOrcamentoFiltroRealizado.vue'),
    RelatorioXMLAgrupado: () => import('../relatorios/orcamento/RelatorioXMLAgrupado.vue'),
    AvaliacaoCasa: () => import('../relatorios/avaliacao/Casa.vue'),
    Abemid: () => import('../relatorios/avaliacao/Abemid.vue'),
    Captacao: () => import('../relatorios/avaliacao/Captacao.vue'),
    Pps:() => import('../relatorios/avaliacao/Paliativo.vue'),
    Nead:() => import('../relatorios/avaliacao/Nead.vue'),
  },
  props: {
    id: { type: null }
  },
  data: () => withCRUDUtils({
    dialog: {
      clone: false,
      agrupado: false,
      agrupadoRecurso: false
    },
    data_envio_faturamento: '',
    data_envio_recurso_glosa: '',
    protocolo_operadora: '',
    protocolo_glosa_operadora: '',
    valor_glosado: 0,
    selectedSessionId: null,
    requiredField: [value => !!value || 'Campo obrigatório'],
    errorMessage: 'Campo obrigatório',
    modalidadeOptions: [],
    operadoraOptions: [],
    formValid: false,
    formValidGlosa: false,
    manutencaoOptions: [
      { nome: "SIM", value: '0' },
      { nome: "NÃO", value: '1' },
    ],
    cobrancaParcial: [
      { nome: "SIM", value: true },
      { nome: "NÃO", value: false },
    ],
    fechamentoOptions: [
      { nome: "SIM", value: '1' },
      { nome: "NÃO", value: '0' },
    ],
    options: {
      locale: "pt-BR",
      prefix: "R$",
      suffix: "",
      length: 11,
      precision: 2
    },
    operadora_: "",
    search: "",
    paciente_: "",
    data_inicio: '',
    data_fim: '',
    modalidades: [],
    activeFilters: [],
    menu: false,
    faturamento: true, 
    filters: {
      dateStart: { value: '', compareType: 'gte', prop: 'data_inicio' },
      dateEnd: { value: '', compareType: 'lte', prop: 'data_fim' },
      modalidade: { value: '', compareType: 'equal', prop: 'modalidade_o.id' },
      operadora: { value: '', compareType: 'equal', prop: 'operadora_o.id' },
      manutencao: { value: '', compareType: 'equal', prop: 'manutencao' },
      fechamento: { value: '', compareType: 'equal', prop: 'fechamento' },
      cobranca_parcial: { value: '', compareType: 'equal', prop: 'cobranca_parcial' },
      senha: { value: '', compareType: 'includes', prop: 'senha' },
      pacienteId: { value: '', compareType: 'includes', prop: 'paciente_id' },
      nomepaciente: { value: '', compareType: 'includes', prop: 'paciente' },
      id: { value: '', compareType: 'includes', prop: 'id' },
    },
    newItem: {},
    showFilters: false,
    pacienteId: 0,
    operadoras: [],
    pacientes: [],
    lista_items: [],
    budgets: [],
    selectedBudgets: [], 
    multipleSelections: [],
    loading: false,
    displayedData: [],
    headers: [
      { text: "Status", align: "center", sortable: true, value: "status" },
      { text: "Editar Conta", align: "center", sortable: false, value: "edit" },
      { text: "Selecionar orçamento gerar PDF", align: "center", sortable: false, value: "select_budget", width: 120 },
      { text: "Gerar XML AGRUPADO", value: 'selectMultiple', sortable: false , width: 100,}, 
      { text: "Nrº Orçamento / Conta", class: "font-weight-black", align: "center", sortable: true, value: "id" },
      { text: "Convênio", align: "center", sortable: true, value: "convenio" },
      { text: "ID Paciente", align: "center", sortable: true, value: "paciente_id" },
      { text: "Paciente", align: "center", sortable: true, value: "paciente_o.nome", width: 300 },
      { text: "Modalidade", align: "center", sortable: true, value: "modalidade_o.nome" },
      { text: "Data Ínicio - Orçamento", align: "center", sortable: true, value: "data_inicio" },
      { text: "Data Término - Orçamento", align: "center", sortable: true, value: "data_fim" },
      { text: "Valor do Orçamento", align: "center", sortable: true, value: "fatorcamento" },
      { text: "Valor da Conta ", align: "center", sortable: true, value: "fatrealizado" },
      { text: "Nr carteirinha", align: "center", sortable: true, value: "nr_carteirinha"  },
      { text: "Senha", align: "center", sortable: true, value: "senha" },
      { text: "Guia Prestador", align: "center", sortable: true, value: "nr_guia_prestador" },
      { text: "Guia Operadora", align: "center", sortable: true, value: "nr_guia_operador" },
      { text: "Protocolo Operadora", align: "center", sortable: true, value: "protocolo_operadora" },
    ],
    selectedOrcamentoId: null,
    idsEvolucoes: [],
    selectedOrcamentoDtInicio: null,
    selectedOrcamentoDtFim: null,
    filteredDatas:[],
    filteredDatasEvolucao: [],
    filteredDatasTQT: [],
    filteredDatasGTT:[],
    isLoadingEvolucoes: false,
    contaspendentes: 0,
    casa: [],
    abemid: [],
    pps:[],
    nead: [],
    captacao: [],
  }),
  methods: {
    formatPatientName() {
      if (this.filters && this.filters.nomepaciente && this.filters.nomepaciente.value) {
        const lowercaseWords = ["a","e", "i", "o", "u", "de", "des", "da", "das", "do", "dos"]; // Palavras que devem permanecer em minúsculas
        this.filters.nomepaciente.value = this.filters.nomepaciente.value
          .toLowerCase()
          .split(" ")
          .map(word => {
            return lowercaseWords.includes(word) ? word : word.charAt(0).toUpperCase() + word.slice(1);
          })
          .join(" ");
      }
    },
    async get_listas(url, lista) {
      try {
        const response = await api.get(url);
        if (response.status === 200) {
          if (response.data instanceof Array && response.data.length) {
            // Ordena o array com base no campo 'nome'
            response.data.sort((a, b) => {
              if (a.nome < b.nome) {
                return -1;
              }
              if (a.nome > b.nome) {
                return 1;
              }
              return 0;
            });

            // Filtra o array para manter apenas os elementos com 'ativo' igual a true
            const filteredData = response.data.filter(item => item.ativo === true);
            // console.log(filteredData)
            // Atualiza a lista com os dados filtrados
            this[lista] = filteredData;
          }
        }
      } catch (error) {
        console.error("Erro ao buscar lista:", error);
      }
    },
    async getOperadoras() {
      try {
        const { data } = await api.get('operadoras/operadoras/');
        this.operadoras = data
          .filter(operadora => operadora && operadora.ativo)
          .sort((a, b) => a.nome.localeCompare(b.nome));
      } catch (error) {
        alert("Erro ao listar as Operadoras")
        console.log(error)
      }
    },
    async getModalidades() {
      try {
        const { data } = await api.get('uteis/modalidades/');
        this.modalidades = data
          .filter(modalidade => modalidade.ativo)
          .sort((a, b) => a.nome.localeCompare(b.nome));
      } catch (error) {
        alert("Erro ao listar as Operadoras")
        console.log(error)
      }
    },
    async getPacientes() {
      try {
        const { data } = await api.get(`pacientes/listar/?operadora=${this.newItem.operadora.id}&ativo=1&empresa=${this.companyData.id}`);
        this.pacientes = data.sort((a, b) => a.nome.localeCompare(b.nome));
      } catch (error) {
        alert("Erro no link ao solicitar equipe");
      }
    },
    async getChildrensOperadoras() {
      this.getPacientes();
    },
    updateDisplayedData() {
      // Filtrar os dados apenas se eles não forem null ou undefined
      const filteredByActiveFilters = this.tableData.filter(item => {
        // Verifica se o item é válido e se passa no filtro ativo
        return item && UtilsFunc.createFilter(this.activeFilters)(item);
      });

      if (this.search) {
        this.displayedData = filteredByActiveFilters.filter(item => {
          // Verifica se o item é válido antes de tentar criar a string de pesquisa
          if (!item) return false;

          const itemString = [
            item.id,
            item.convenio,
            item.paciente,
            item.modalidade,
            item.senha,
            item.nr_guia_operador,
            item.nr_guia_prestador,
            item.paciente_id
          ].join(' ').toLowerCase();

          return itemString.includes(this.search.toLowerCase());
        });
      } else {
        this.displayedData = filteredByActiveFilters;
      }

      // Verifica e formata dados se item não for null
      this.displayedData = this.displayedData.map(item => {
        if (!item) return null;
        return {
          ...item,
          fatorcamentoFormatted: item.fatorcamento,
          fatrealizadoFormatted: item.fatrealizado
        };
      }).filter(item => item !== null); // Remove qualquer item null da lista final

      // Recalcula o número de guias incompletas
      this.contaspendentes = this.displayedData.filter(item => item && item.fechamento === 0).length;
    },
    async getSessions() {
      const LOADING_NAME = 'get'
      this.setLoading(LOADING_NAME)
      try {
        let params = {
              date_start: this.filters.dateStart.value,
              date_end: this.filters.dateEnd.value,
              operadora: this.filters.operadora.value ? this.filters.operadora.value : 0,
              modalidade: this.filters.modalidade.value ? this.filters.modalidade.value : 0,
              empresa: 1,
          };
          // Adiciona o campo "ativo" apenas se o valor for true ou false
          // if (this.filters.ativo.value === true || this.filters.ativo.value === false) {
          //   params.ativo = this.filters.ativo.value === true ? 'true' : 'false';
          // }
          // console.log('aqui')

          if (this.filters.id.value) {
              params.id = this.filters.id.value;
          }
          
          if (this.filters.nomepaciente.value) {
              params.nomepaciente = this.filters.nomepaciente.value
          }
          if (this.filters.pacienteId.value) {
              params.paciente = this.filters.pacienteId.value
          }
          if (this.filters.senha.value) {
              params.senha = this.filters.senha.value
          }
          if (this.filters.manutencao.value === true || this.filters.manutencao.value === false) {
              params.manutencao = this.filters.manutencao.value === true ? 'true' : 'false';
          }
          if (this.filters.cobranca_parcial.value === true || this.filters.cobranca_parcial.value === false) {
              params.cobranca_parcial = this.filters.cobranca_parcial.value === true ? 'true' : 'false';
          }
          if (this.filters.fechamento.value === true || this.filters.fechamento.value === false) {
              params.fechamento = this.filters.fechamento.value === true ? 'true' : 'false';
          }
        
          // console.log(params)
          const { data } = await api.get("atendimentos/orcamentos/", { params });
          this.tableData = data.filter(item => item.reprovado != 1 && item.ativo == true) // excluir os orçamentos reprovados
          // .filter(e => e.ativo !== false) // Filtra os objetos com ativo diferente de false
          
          .map(e => {
            try {
              e['paciente_o'] = JSON.parse(e.paciente_obj) || '{}';
              e['operadora_o'] = JSON.parse(e.operadora_obj) || '{}';
              e['modalidade_o'] = JSON.parse(e.modalidade) || '{}';
            } catch (error) {
              console.error('Erro na string JSON em e.paciente_obj:', e.paciente_obj);
              console.error('Erro na string JSON em e.operadora_obj:', e.operadora_obj);
              console.error('Erro na string JSON em e.modalidade:', e.modalidade);
              console.error(error);
            }

            return e;
            
          })
          .sort((a, b) => {
            // Ordena primeiro pelas contas com manutencao = '0'
            if (a.manutencao === '0' && b.manutencao !== '0') {
              return -1; // Mantém 'a' antes de 'b'
            } else if (a.manutencao !== '0' && b.manutencao === '0') {
              return 1; // Move 'b' antes de 'a'
            } else {
              // Se ambos têm o mesmo valor de manutencao ou se ambos não têm manutencao '0',
              // então ordena pelo valor de fechamento
              if (a.fechamento === '0' && b.fechamento !== '0') {
                return -1; // Mantém 'a' antes de 'b' se 'a' tiver fechamento = '0' e 'b' não
              } else if (a.fechamento !== '0' && b.fechamento === '0') {
                return 1; // Move 'b' antes de 'a' se 'b' tiver fechamento = '0' e 'a' não
              } else {
                // Se ambos têm o mesmo valor de fechamento ou se ambos não têm fechamento '0',
                // então ordena por data_inicio
                const dateA = new Date(a.data_inicio);
                const dateB = new Date(b.data_inicio);
                return dateA - dateB;
              }
            }
          });
          this.displayedData = this.filteredData;
          // console.log(this.tableData)

        this.contaspendentes = this.displayedData.filter(item => item.fechamento == '0').length;
        this.updateDisplayedData();
      } catch (error) {
        this.$toast.error(`Desculpe, Aconteceu algum erro... ${error}`)
      } finally {
        this.setLoading(LOADING_NAME, true)
      }
    },
    async updateSession(fields) {
      const LOADING_NAME = 'put:sessao'
      this.setLoading(LOADING_NAME)
      try {
        await api.put(`atendimentos/sessoes_put/${fields.id}/`, fields)
        this.$toast.success('Alteração Realizada com Sucesso!')
        await this.getSessions()
      } catch (error) {
        this.$toast.error(`Desculpe, Aconteceu algum erro... ${error}`)
      } finally {
        this.setLoading(LOADING_NAME, true)
      }
    },
    clearFilters() {
      Object.keys(this.filters).forEach((prop) => this.filters[prop].value = '')
      this.calcularDatasDinamicas()
      this.activeFilters = []
      this.updateDisplayedData();
      
      // this.dateStartError = false;
      // this.dateEndError = false;
      this.search = '';
      this.contaspendentes = 0;
      this.multipleSelections = [];
      this.selectedBudgets = [];
    },
    clearSelecao(){
      this.selectedBudgets = [];
      this.multipleSelections = [];
    },
    applyFilters() {
      this.selectedBudgets = [];
      this.multipleSelections = [];
      // Verifica se todos os filtros estão vazios
      const areAllFiltersEmpty = Object.values(this.filters).every(filter => !filter.value);
      
      if (areAllFiltersEmpty) {
        this.$toast.error('Por favor, escolha pelo menos um critério de pesquisa.');
        return;
      }

      const filters = JSON.parse(JSON.stringify(this.filters));
      this.activeFilters = Object.values(filters).filter(filter => {
        // Inclui o filtro somente se ele tem um valor (não nulo e não vazio)
        if (filter.value === '' || filter.value === null) {
          return false;
        }

        // Tratamento especial para campos como 'modalidade_o.id'
        if (filter.prop === 'modalidade_o.id') {
          console.log('applyfilters',this.tableData)
          return this.tableData.some(data =>
            data.modalidade_o && data.modalidade_o.id === filter.value
          );
        }

        return true;
      });

      this.getSessions();
    },
    pesquisar() {
      this.applyFilters()
    },
    handleCheckboxChange(item) {
      this.idsEvolucoes = [];
      this.filteredDatasEvolucao = [];
      this.filteredDatasTQT = [];
      this.filteredDatasGTT = [];
      this.multipleSelections = [];
      this.casa = [];
      this.abemid = [];
      this.pps = [];
      this.nead = [];
      this.captacao = [];
      if (this.selectedBudgets.includes(item.id)) {
        this.selectedBudgets = [];
        this.selectedOrcamentoId = null;
      } else {
        this.selectedBudgets = [item.id];
        this.selectedOrcamentoId = item.id;
        this.selectedOrcamentoDtInicio = item.data_inicio
        this.selectedOrcamentoDtFim = item.data_fim
        this.getEvolucoes()
      }
    },
    validateSameOperadora(selectedIds) {
      // Retorna true se não houver orçamentos selecionados (primeira seleção é sempre válida)
      if (selectedIds.length <= 1) return true;

      // Obtém a operadora do primeiro item selecionado para comparação
      const firstSelectedOperadora = this.tableData.find(item => item.id === selectedIds[0]).operadora_o.id;
      
      // Verifica se todos os orçamentos selecionados têm a mesma operadora
      return selectedIds.every(id => {
        const itemOperadora = this.tableData.find(item => item.id === id).operadora_o.id;
        return itemOperadora === firstSelectedOperadora;
      });
    },
    areAllSelectedAccountsClosed() {
      return this.multipleSelections.every(id => {
        const selectedAccount = this.tableData.find(item => item.id === id);
        return selectedAccount && selectedAccount.fechamento === '1';
    });
    },
    handleMultipleSelectionsChange(value) {
      if (!this.validateSameOperadora(value)) {
        this.$toast.error('Todos os orçamentos selecionados devem ser da mesma operadora.');
        // Remove o último item selecionado que causou a falha na validação
        this.multipleSelections.pop();
        return;
      }
      this.selectedBudgets = []; // Limpa a seleção de orçamento ao selecionar para XML AGRUPADO
      this.multipleSelections = value;
      // Outras operações necessárias...

      if (!this.areAllSelectedAccountsClosed()) {
        this.$toast.error('Todas as contas selecionadas devem estar fechadas.');
        this.multipleSelections.pop();
      return;
    }
    },
    isCheckboxDisabled(item) {
      // Se não houver itens selecionados, nenhuma caixa de seleção deve ser desabilitada
      if (this.multipleSelections.length === 0) return false;

      // Obtém a operadora do primeiro item selecionado
      const firstSelectedOperadora = this.tableData.find(data => data.id === this.multipleSelections[0]).operadora_o.id;

      // Desabilita a caixa de seleção se a operadora do item atual for diferente da dos itens já selecionados
      return item.operadora_o.id !== firstSelectedOperadora;
    },
    async getEvolucoes() {
      this.isLoadingEvolucoes = true;
      try {
        const { data } = await api.get(`atendimentos/evolucoes/?sessao=${this.selectedOrcamentoId}`)
        this.filteredDatas = data.filter(item => item.assinatura !== null || item.espeical && item.ativo === true);
        this.filteredDatasEvolucao = data.filter(item => item.assinatura !== null && item.ativo === true && !(item.procedimento.procedimento_id === 16 || item.procedimento.procedimento_id === 17));
        this.filteredDatasTQT = data.filter(item => item.assinatura !== null && item.ativo === true && item.procedimento.procedimento_id === 16 );
        this.filteredDatasGTT = data.filter(item => item.assinatura !== null && item.ativo === true && item.procedimento.procedimento_id === 17 );
        this.casa = data.filter(item => item.ativo === true && item.casa !== null)
        this.abemid = data.filter(item => item.ativo === true && item.abemid !== null)
        this.pps = data.filter(item => item.ativo === true && item.pps !== null)
        this.nead = data.filter(item => item.ativo === true && item.nead !== null)
        this.captacao = data.filter(item => item.ativo === true && item.captacao !== null)
        this.idsEvolucoes = data
        .filter(item => item.assinatura !== null || item.espeical && item.ativo === true)
        .map(item => item.id);
      } catch (error) {
        this.$toast.error(`Desculpa, aconteceu alguma coisa errada. Erro -> ${error}`)
      } finally {
        this.isLoadingEvolucoes = false;
      }
    },
    async gerarRelatoriosCombinados() {
    
      const today = new Date().toISOString().split('T')[0];
      const sessionData = this.tableData.filter(item => item.orcamento === this.selectedBudgets[0]);
      const data = sessionData[0].data_envio_faturamento
      const protocolo = sessionData[0].protocolo_operadora
      this.data_envio_faturamento = data || today;
      this.protocolo_operadora = protocolo || '';
      this.dialog.agrupado = true;

      if (this.$refs.evolucoesPorEspecialidadeComponent) {
        await this.$refs.evolucoesPorEspecialidadeComponent.generatePDF(true);
      }
      this.$refs.orcamento.getPDF();
      this.$refs.conta.getPDF();
      this.$refs.xml.getXML();
      this.$refs.guiaSadt2.getPDF(false);
    },
    async gerarXMLAgrupadoERelatorio() {
      if (this.multipleSelections.length > 0) {
        this.selectedSessionId = this.multipleSelections[0]; 
      
      const today = new Date().toISOString().split('T')[0];
      // Obtenha a sessão selecionada
      const sessionData = this.tableData.find(item => item.id === this.selectedSessionId);
      
      // Defina os valores dos campos no modal
      this.data_envio_faturamento = sessionData.data_envio_faturamento || today;
      this.protocolo_operadora = sessionData.protocolo_operadora || '';
      
      // Assumindo que o usuário selecionou apenas uma sessão para XML agrupado
      this.dialog.agrupado = true;
      this.$refs.relatorioxml.generatePDF();
      await this.$refs.xmlagrupado.getXML();
        
      } else {
        this.$toast.error('Selecione ao menos uma sessão para gerar o relatório.');
      }
    },
    async gerarXMLAgrupado() {
      if (this.multipleSelections.length > 0) {
        this.selectedSessionId = this.multipleSelections[0]; 
        const today = new Date().toISOString().split('T')[0];
        const sessionData = this.tableData.find(item => item.id === this.selectedSessionId);
        this.data_envio_faturamento = sessionData.data_envio_faturamento || today;
        this.protocolo_operadora = sessionData.protocolo_operadora || '';
        this.dialog.agrupado = true;
      } else {
        this.$toast.error('Selecione ao menos uma sessão para gerar o relatório.');
      }
    },
    async gerarXMLIsaolado() {
      const today = new Date().toISOString().split('T')[0];
      const sessionData = this.tableData.filter(item => item.orcamento === this.selectedBudgets[0]);
      const data = sessionData[0].data_envio_faturamento
      const protocolo = sessionData[0].protocolo_operadora
      const dataGlosa = sessionData[0].data_envio_recurso_glosa
      const protocoloGlosa = sessionData[0].protocolo_glosa_operadora
      const valorGlosado = sessionData[0].valor_glosado
      this.data_envio_faturamento = data || null;
      this.protocolo_operadora = protocolo || null;
      this.data_envio_recurso_glosa = dataGlosa || today;
      this.protocolo_glosa_operadora = protocoloGlosa || null
      this.valor_glosado = valorGlosado || null
      this.dialog.agrupado = true;

    },
    async gerarXMLIsaoladoRecrusoGlosa() {
      this.abrirModalRecursoGlosa();
      const today = new Date().toISOString().split('T')[0];
      const sessionData = this.tableData.filter(item => item.orcamento === this.selectedBudgets[0]);
      const data = sessionData[0].data_envio_faturamento
      const protocolo = sessionData[0].protocolo_operadora
      const dataGlosa = sessionData[0].data_envio_recurso_glosa
      const protocoloGlosa = sessionData[0].protocolo_glosa_operadora
      const valorGlosado = sessionData[0].valor_glosado ? sessionData[0].valor_glosado : sessionData[0].glosado
      this.data_envio_faturamento = data || null;
      this.protocolo_operadora = protocolo || null;
      this.data_envio_recurso_glosa = dataGlosa || today;
      this.protocolo_glosa_operadora = protocoloGlosa || null
      this.valor_glosado = valorGlosado || null
      this.dialog.agrupadoRecurso = true;

    },
    async confirmarFaturamento() {
      const LOADING_NAME = 'put:sessaoInformacao';
      this.setLoading(LOADING_NAME);
      try {
          if (this.multipleSelections && this.multipleSelections.length > 0) {
              // Primeira parte: usando multipleSelections
              for (let sessionId of this.multipleSelections) {
                  let sessionData = this.tableData.find(item => item.id === sessionId);

                  let hospitalData = JSON.parse(sessionData.hospital || '{}'); // Parse o JSON ou use um objeto vazio

                  if (hospitalData && Object.keys(hospitalData).length > 0) {
                      sessionData.hospital_o = hospitalData; // Define hospital_o como o objeto completo
                      sessionData.hospital = hospitalData.id; // Define hospital como o ID do hospital
                  } else {
                      delete sessionData.hospital_o; // Remove hospital_o se não houver dados
                      delete sessionData.hospital; // Remove hospital se não houver dados
                  }

                  let motivo_suspensao_atendimentoData = JSON.parse(sessionData.motivo_suspensao_atendimento || '{}'); // Parse o JSON ou use um objeto vazio

                  if (motivo_suspensao_atendimentoData && Object.keys(motivo_suspensao_atendimentoData).length > 0) {
                      sessionData.motivo_suspensao_atendimento_o = motivo_suspensao_atendimentoData; // Define motivo_suspensao_atendimento_o como o objeto completo
                      sessionData.motivo_suspensao_atendimento = motivo_suspensao_atendimentoData.id; // Define motivo_suspensao_atendimento como o ID do motivo_suspensao_atendimento
                  } else {
                      delete sessionData.motivo_suspensao_atendimento_o; // Remove motivo_suspensao_atendimento_o se não houver dados
                      delete sessionData.motivo_suspensao_atendimento; // Remove motivo_suspensao_atendimento se não houver dados
                  }

                  const fields = {
                      ...sessionData,
                      pacote: this.pacote_id,
                      data_envio_faturamento: this.data_envio_faturamento || null,
                      protocolo_operadora: Number(this.protocolo_operadora) || null,
                      data_envio_recurso_glosa: this.data_envio_recurso_glosa || null,
                      protocolo_glosa_operadora: Number(this.protocolo_glosa_operadora) || null,
                      valor_glosado: Number(this.valor_glosado) || 0,

                  };
                  // console.log(fields)
                  await this.updateSession(fields);
              }
              this.dialog.agrupado = false;
              this.dialog.agrupadoRecurso = false;
              this.$toast.success('Informações de faturamento salvas com sucesso 1.');
          } else if (this.selectedBudgets && this.selectedBudgets.length > 0) {
              // Segunda parte: usando selectedBudgets
              let sessionData = this.tableData.find(item => item.orcamento === this.selectedBudgets[0]);

              let hospitalData = JSON.parse(sessionData.hospital || '{}'); // Parse o JSON ou use um objeto vazio

                  if (hospitalData && Object.keys(hospitalData).length > 0) {
                      sessionData.hospital_o = hospitalData; // Define hospital_o como o objeto completo
                      sessionData.hospital = hospitalData.id; // Define hospital como o ID do hospital
                  } else {
                      delete sessionData.hospital_o; // Remove hospital_o se não houver dados
                      delete sessionData.hospital; // Remove hospital se não houver dados
                  }

                  let motivo_suspensao_atendimentoData = JSON.parse(sessionData.motivo_suspensao_atendimento || '{}'); // Parse o JSON ou use um objeto vazio

                  if (motivo_suspensao_atendimentoData && Object.keys(motivo_suspensao_atendimentoData).length > 0) {
                      sessionData.motivo_suspensao_atendimento_o = motivo_suspensao_atendimentoData; // Define motivo_suspensao_atendimento_o como o objeto completo
                      sessionData.motivo_suspensao_atendimento = motivo_suspensao_atendimentoData.id; // Define motivo_suspensao_atendimento como o ID do motivo_suspensao_atendimento
                  } else {
                      delete sessionData.motivo_suspensao_atendimento_o; // Remove motivo_suspensao_atendimento_o se não houver dados
                      delete sessionData.motivo_suspensao_atendimento; // Remove motivo_suspensao_atendimento se não houver dados
                  }


              let fields = {
                  ...sessionData,
                  pacote: this.pacote_id,
                  data_envio_faturamento: this.data_envio_faturamento,
                  protocolo_operadora: Number(this.protocolo_operadora),
                  data_envio_recurso_glosa: this.data_envio_recurso_glosa || null,
                  protocolo_glosa_operadora: Number(this.protocolo_glosa_operadora) || null,
                  valor_glosado: Number(this.valor_glosado) || 0,
              };
              await this.updateSession(fields);
              this.dialog.agrupado = false;
              this.dialog.agrupadoRecurso = false;
              this.$toast.success('Informações de faturamento salvas com sucesso 2.');
          } else {
              this.$toast.error('Nenhuma seleção feita para faturamento.');
          }
      } catch (error) {
          this.dialog.agrupadoRecurso = false;
          this.$toast.error(`Erro ao salvar as informações de faturamento. ${error}`);
      } finally {
          this.setLoading(LOADING_NAME, true);
      }
    },
    calcularDatasDinamicas() {
      // Obter o primeiro dia do mês anterior
      const today = new Date();
      const ontem = new Date(today);
      ontem.setDate(today.getDate() - 1);
      const primeiroDiaDoMesAtual = new Date(today.getFullYear(), today.getMonth(), 1);
      const primeiroDiaDoMesAnterior = new Date(primeiroDiaDoMesAtual);
      primeiroDiaDoMesAnterior.setMonth(primeiroDiaDoMesAtual.getMonth() - 2);

      // Formatar as datas no formato YYYY-MM-DD
      this.filters.dateStart.value = `${primeiroDiaDoMesAnterior.getFullYear()}-${(primeiroDiaDoMesAnterior.getMonth() + 1).toString().padStart(2, '0')}-${primeiroDiaDoMesAnterior.getDate().toString().padStart(2, '0')}`;
      this.filters.dateEnd.value = `${ontem.getFullYear()}-${(ontem.getMonth() + 1).toString().padStart(2, '0')}-${ontem.getDate().toString().padStart(2, '0')}`;
    },
    async atualizar() {
      this.clearFilters();
      this.get_listas('operadoras/app-operadoras/', 'operadoraOptions')
      this.get_listas('uteis/app-modalidades/', 'modalidadeOptions')
      this.getOperadoras()
      this.getSessions()
      this.getModalidades()
      this.calcularDatasDinamicas();
      this.applyFilters()
      this.updateDisplayedData();
      if (this.id !== undefined) {
        this.activeFilters = [{ value: Number(this.id), compareType: 'equal', prop: 'id' }]
          ; (async () => {
            await this.getSessions()
            await this.getOperadoras()

            if (this.id) {
              const currentItem = this.tableData.find(r => r.id === Number(this.id))
              this.selectedRow = { ...currentItem }
              this.dialog.update = true
            }
          })()
      } else {
        this.getSessions();
      }
    },
    exportToExcel() {
      try {
        const worksheet = XLSX.utils.json_to_sheet(this.filteredData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Contas');

        const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        saveAs(new Blob([wbout], { type: 'application/octet-stream' }), 'contas.xlsx');
      } catch (error) {
        console.error('Erro ao exportar para Excel:', error);
      }
    },
    abrirModalRecursoGlosa() {
      this.data_envio_recurso_glosa = ''; // Resetando campo de data
      this.protocolo_glosa_operadora = ''; // Resetando campo de protocolo
      this.valor_glosado = ''; // Resetando campo de valor glosado
      this.dialog.agrupadoRecurso = true; // Abrir o modal
    },
    fechamodal(fechamento){
      // console.log('modal acionado ', this.dialog.agrupadoRecurso)
      this.dialog[fechamento] = false
      // console.log('modal acionado depois ', this.dialog.agrupadoRecurso)
    },
     handleValorGlosadoInput(value) {
      // Se o valor for vazio, define como 0
      this.valor_glosado = value === '' || value === null ? 0 : value;
    },
  },
  mounted() {
    // console.log('oia eu aqui')
    this.get_listas('operadoras/app-operadoras/', 'operadoraOptions')
    this.get_listas('uteis/app-modalidades/', 'modalidadeOptions')
    this.getOperadoras()
    this.getModalidades()
    this.calcularDatasDinamicas();
    this.updateDisplayedData();

  },
  computed: {
    ...mapGetters(['companyData', 'userData']),
    selectedRows() {
      return this.filteredData.filter(row => this.lista_items.includes(row.id))
    },
    paciente() {
      return this.pacientes.find(p => p.id === this.pacienteId) || {}
    },
    filteredData() {
    // Verifica se há algum filtro ativo ou se o campo de pesquisa não está vazio
    const isFilterApplied = this.activeFilters.length > 0;
    const isSearchApplied = this.search.trim().length > 0;

    // Retorna os dados filtrados apenas se um filtro ou pesquisa estiver ativo
    if (isFilterApplied || isSearchApplied) {
      return this.displayedData;
    }

    // Retorna um array vazio se nenhum filtro ou pesquisa estiver ativo
    return [];
  },
    isContaFechada() {
      if (this.selectedBudgets.length > 0) {
        const selectedOrcamento = this.tableData.find(item => item.id === this.selectedBudgets[0]);
        return selectedOrcamento && selectedOrcamento.fechamento === '1';
      }
      return false;
    },
    isContaFechadaXML() {
      if (this.multipleSelections.length > 0) {
        return this.areAllSelectedAccountsClosed();
      }
      return false;
    },
    isContaRecursoGlosa() {
      if (this.selectedBudgets.length > 0) {
        const selectedOrcamento = this.tableData.find(item => item.id === this.selectedBudgets[0]);
        return selectedOrcamento && selectedOrcamento.realizarrecursoglosa === true;
      }
      return false;
    },
     guiasIncompletas() {
      return this.filteredData.reduce((count, item) => {
        // Uma guia é considerada incompleta se qualquer um desses campos for null
        if (item.senha === null ||
            item.nr_guia_operador === null ||
            item.nr_guia_prestador === null ||
            item.senha_data_inicio === null ||
            item.senha_data_validade === null) {
          return count + 1;
        }
        return count;
      }, 0);
    },
     headersWithGuiasIncompletas() {
      // Clona o array de headers para não modificar o original armazenado em `data`
      const updatedHeaders = this.headers.map(header => ({ ...header }));

      // Encontra o índice do cabeçalho onde o texto deve ser atualizado
      const nrOrcamentoIndex = updatedHeaders.findIndex(header => header.value === 'id');

      // Se encontrou o cabeçalho, atualiza seu texto para incluir a contagem de guias incompletas
      if (nrOrcamentoIndex !== -1 && this.guiasIncompletas > 0) {
        updatedHeaders[nrOrcamentoIndex].text = `"Nrº Orçamento / Conta" (${this.guiasIncompletas} guias incompletas)`;
      }

      return updatedHeaders;
    },
     headersWithDynamicInfo() {
      // Clona o array de headers para não modificar o original
      const headers = this.headers.map(header => ({ ...header }));

      // Encontra o índice do cabeçalho "Nrº Orçamento / Conta"
      const nrOrcamentoIndex = headers.findIndex(header => header.value === 'id');
      if (nrOrcamentoIndex !== -1 && this.guiasIncompletas > 0) {
        headers[nrOrcamentoIndex].text = `Nrº Orçamento / Conta (${this.guiasIncompletas} guias incompletas)`;
      }

      const xmlAgrupadoIndex = headers.findIndex(header => header.text.includes('Gerar XML AGRUPADO'));
      if (xmlAgrupadoIndex !== -1) {
        if (this.multipleSelections.length === 1) {
          headers[xmlAgrupadoIndex].text = `Gerar XML AGRUPADO (${this.multipleSelections.length} conta)`;
        } else if (this.multipleSelections.length > 1) {
          headers[xmlAgrupadoIndex].text = `Gerar XML AGRUPADO (${this.multipleSelections.length} contas)`;
        } else {
          headers[xmlAgrupadoIndex].text = 'Gerar XML AGRUPADO'; // Sem seleções
        }
      }
      return headers;
    },
    lista() {
      return this.userData || {}
    },
  },
  watch: {
    fsearch() {
      this.updateDisplayedData();
    },
    activeFilters() {
      this.updateDisplayedData();
    },
    search() {
      this.updateDisplayedData();
    },
    filteredData(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.contaspendentes = newVal.filter(item => item.fechamento === '0').length;
      }
    },
  },

};
</script>
<style scoped>
.container {
  display: flex;
  width: 100%;
  gap: 0.2rem;
  flex-direction: column;
  background-color: white;
  /* align-items: center; */
}
.sem-evolucoes {
  color: red;
  text-align: center;
  /* Outras propriedades de estilo se necessário */
}
.com-evolucoes {
  color: green;
  text-align: center;
  /* Outras propriedades de estilo se necessário */
}
.title-border {
  border-bottom: 2px solid #E0E0E0 !important; 
  padding-bottom: 10px !important;
}
.title-container {
  display: flex;
  align-items: center;
  justify-content: center; /* Centraliza os elementos horizontalmente */
  flex-wrap: nowrap; /* Impede que os elementos sejam quebrados em linhas diferentes */
}

.h1 {
  margin: 0; /* Remove margens padrão para melhor alinhamento */
  flex-shrink: 0; /* Impede que o título encolha, forçando a badge a se mover para baixo */
}

.badge {
  background-color: #FAAF19 ;
  color: #0D518F;
  border-radius: 50%;
  /* padding: 5px 10px; */
  font-size: 0.8rem;
  margin-left: 10px; /* Espaço entre o título e a badge */
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 20px;
  height: 20px;
  flex-shrink: 0; /* Impede que a badge encolha */
}
.alert-message {
  color: red;
  margin-top: 10px;
  width: 300px;
  white-space: pre-wrap; 
}

.table-container {
  max-height: 500px; /* Define a altura máxima para o contêiner da tabela */
  overflow-y: auto;  /* Permite a rolagem vertical */
}

.fixed-header-table thead th {
  background-color: white; /* Cor de fundo do cabeçalho para que ele permaneça visível */
  position: sticky;
  top: 0;
  z-index: 1;
}

</style>